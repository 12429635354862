import React, { useState, useMemo } from 'react'

// create context
export const SidebarContext = React.createContext(undefined)

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)



  const value = useMemo(
    () => {
      function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen)
      }

      function closeSidebar() {
        setIsSidebarOpen(false)
      }

      return {
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
      }
    },
    [isSidebarOpen]
  )

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}
